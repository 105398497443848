import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Box, Container, TextField } from "@mui/material";
import useStyles from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroButton from "../../../../components/CeroButton";
import Status from "./Status";
import {
  getInitiativeDetails,
  getTasksList,
  resetGoals,
  saveTaskTracking,
} from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CeroTable from "../../../../components/CeroTable";
import { STATUS } from "../../../../redux/constants";
import { useSnackbar } from "notistack";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import { getCookie } from "../../../../services/cookie";

export const taskTableColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Task",
    width: "10vw",
  },
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
    width: "8vw",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Progress%",
    width: "8vw",
  },
  {
    columnKey: "notes",
    columnId: "notes",
    columnHeader: "Notes",
    width: "15vw",
  },
  {
    columnKey: "edit",
    columnId: "edit",
    columnHeader: "",
    width: "3vw",
  },
];

const InitiativeTrackingDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedId, setSelectedId] = useState("");
  const [progressValue, setProgressValue] = useState({});
  const [notesValue, setNotesValue] = useState({});
  const [editingTasks, setEditingTasks] = useState({});
  const [editedTaskIds, setEditedTaskIds] = useState(new Set());
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const handlePercentageChange = (e, taskId) => {
    setSelectedId(taskId)
    var progress = e.target.value;
    setProgressValue((prevMap) => ({
      ...prevMap,
      [taskId]: progress,
    }));
    handleEdit(e, taskId)
  };

  const handleDetailsChange = (e, taskId) => {
    setSelectedId(taskId)
    var note = e.target.value;
    setNotesValue((prevMap) => ({
      ...prevMap,
      [taskId]: note,
    }));
    handleEdit(e, taskId)
  };

  const initiativeDetails = useSelector(
    (state) => state.goalSettings.getInitiativeDetails.data
  );

  const taskDetails = useSelector(
    (state) => state.goalSettings.getTasksList.data
  );

  const saveTask = useSelector((state) => state.goalSettings.saveTaskTracking);

  useEffect(() => {
    dispatch(getInitiativeDetails(id));
    dispatch(getTasksList(id));
  }, [dispatch, id]);

  const handleEdit = (e, taskId) => {
    e.preventDefault();
    e.stopPropagation();
    // setTaskId(taskId);
    setEditedTaskIds((prevSet) => new Set(prevSet.add(taskId)));
      setEditingTasks(prevState => ({
        ...prevState,
        [taskId]: true,
      }));
  };

  const onSaveTaskDetails = () => {
    const updatedTasks = taskDetails.map((task) => {
    let progress = task.progress;
    let note = task.note;
    setSelectedId("")
    if (editedTaskIds.has(task.id)) {
      progress = progressValue[task.id] || task.progress;
      note = notesValue[task.id] || task.note;
    }
    else if (task.id === selectedId) {
      progress = progressValue[selectedId] || task.progress;
      note = notesValue[selectedId] || task.note;
    }
        return {
          ...task,
          progress,
          note,
        };
    });
    dispatch(saveTaskTracking(id, updatedTasks));
  };
  
  useEffect(() => {
    if (saveTask.status === STATUS.SUCCESS) {
      dispatch(resetGoals());
      dispatch(getTasksList(id));
      setEditingTasks(prevState => {
        const newState = {};
        for (const key in prevState) {
          newState[key] = false;
        }
        return newState;
      });
      
      enqueueSnackbar("Saved successfully", { variant: "success", autoHideDuration: 3000, });
    } else if (saveTask.status === STATUS.ERROR) {
      dispatch(resetGoals());
      enqueueSnackbar(
        "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000, }
      );
    }
  }, [saveTask, dispatch, id]);

  const getInitiativesList = () => {
    if (!Array.isArray(taskDetails)) {
      return [];
    }
    return taskDetails?.map((item) => {
      return {
        ...item,
        action: editingTasks[item.id] ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={progressValue[item.id] || item.progress}
            onChange={(e) => handlePercentageChange(e, item.id)}
            placeholder="Enter %"
            disabled={readOnlyMode}
            type="number"
          />
        ) : item.progress === null ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={progressValue[item.id] || item.progress}
            onChange={(e) => handlePercentageChange(e, item.id)}
            placeholder="Enter %"
            disabled={readOnlyMode}
          />
        ) : (
          item.progress
        ),
        notes: editingTasks[item.id] ? (
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={2}
            value={notesValue[item.id] || item.note}
            onChange={(e) => handleDetailsChange(e, item.id)}
            placeholder="Enter details"
            disabled={readOnlyMode}
          />
        ) : item.note === null ? (
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={2}
            value={notesValue[item.id] || item.note}
            onChange={(e) => handleDetailsChange(e, item.id)}
            placeholder="Enter details"
            disabled={readOnlyMode}
          />
        ) : (
          item.note
        ),
        edit:
          !readOnlyMode && (
            <CreateIcon
              onClick={(e) => handleEdit(e, item.id)}
              className={classes.editIcon}
            />
          ),
      };
    });
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.buttonContainer}>
          <div
            className={classes.backContainer}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon />
            Back
          </div>
         <div>
            <CeroButton
              buttonText="Save"
              className={classes.buttonPrimary}
              onClick={onSaveTaskDetails}
              disabled={readOnlyMode}
            />
          </div>
        </Box>
        <Status details={initiativeDetails} />
        <CeroTable
          columns={taskTableColumns}
          data={getInitiativesList()}
          hasMore={false}
          loading={false}
          classes={{ tableBodyCell: classes.tableBodyCell }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default InitiativeTrackingDetails;

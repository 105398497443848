import React from 'react';
import useStyles from './styles'; // Ensure correct import
import StatisticsCard from '../Dashboard/Statistics/StatisticsCard';

export const GridLayout = ({ type, components, tab }) => {
  const classes = useStyles();
  console.log('nik',tab);
  
  const filterCard = components.map(item=> item.name=='Filters')

  // Determine the number of components per row based on the type
  const getComponentsPerRow = () => {
    switch (type) {
      case 1: return 2; // 3 by 3 grid
      case 2: return 1; // 2 by 3 grid
      case 3: return 2; // 1 by 3 grid
      case 4: return 2;
      case 5: return 2;
      case 6: return 2; // 2 by 3 with custom column spans
      default: return 2; // Default to 3 by 3 if type is unknown
    }
  };

  const componentsPerRow = getComponentsPerRow();

  // Function to determine class based on type and index
  const getClassForComponent = (index) => {
    if (type === 5) {
      return index % 2 === 0 ? classes.column8 : classes.column4; // Type 4: 8-4 grid
    } else if (type === 6) {
      return index % 2 === 0 ? classes.column4 : classes.column8; // Type 6: 4-8 grid
    } else {
      return classes.column; // Default for other types
    }
  };

  // Split components into rows based on the number of components per row
  const rows = [];
  
  const filterComponent = components.find(component => component.name === 'Filters');
  const widgetComponents = components.filter(component => component.graphComponent === StatisticsCard);
  const otherComponentsSmall = components.filter(component => component.name !== 'Filters' && component.graphComponent !== StatisticsCard && (component.size=='m' || component.size=='s'));
  const otherComponentsLarge = components.filter(component => component.name !== 'Filters' && component.graphComponent !== StatisticsCard && (component.size=='l'));

// Add the "filter" component in its own row, if it exists
if (filterComponent) {
  rows.push([filterComponent]);
}

// Add the "widgets" components in rows after the "filter" component, if any exist
if (widgetComponents.length > 0) {
  for (let i = 0; i < widgetComponents.length; i += 5) {
    rows.push(widgetComponents.slice(i, i + 5));
  }
}

// Add the remaining components in rows
for (let i = 0; i < otherComponentsSmall.length; i += componentsPerRow) {
  rows.push(otherComponentsSmall.slice(i, i + componentsPerRow));
}

for (let i = 0; i < otherComponentsLarge.length; i += 1) {
  rows.push(otherComponentsLarge.slice(i, i + 1));
}

  return (
    <div className={classes.gridContainer}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className={tab >= 0? classes.rowAdjusted: classes.row}>
          {row.map((component, colIndex) => (
            <div
              key={colIndex}
              className={
                component.name=='Filters' ? classes.eachGraphF : component.graphComponent === StatisticsCard ? classes.eachGraphStatistics : component.size =='l' ? classes.columnType2 : type === 1 || type === 3 || type === 4 
                  ? classes.columnType3 : type === 2 ? classes.columnType2
                  : getClassForComponent(colIndex) // For type 4 and 6
              }
            >
              {<component.graphComponent {...{...component.data, refreshContent:[2,3].includes(tab)}}/>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

// Usage Example
export const ExampleComponent = () => {
  const componentsArray = Array.from({ length: 5 }, (_, i) => <div key={i}>Component {i + 1}</div>);

  return (
    <>
    <h2>Type 1</h2>
    
      <GridLayout type={1} components={componentsArray} />
      <h2>Type 2</h2>
      <GridLayout type={2} components={componentsArray} />
      <h2>Type 3</h2>
      <GridLayout type={3} components={componentsArray} />
      <h2>Type 4</h2>
      <GridLayout type={4} components={componentsArray} />
      <h2>Type 5</h2>
      <GridLayout type={5} components={componentsArray} />
      <h2>Type 6</h2>
      <GridLayout type={6} components={componentsArray} />


    </>
  );
};



import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CeroAutoComplete from "../../../../../components/CeroAutoComplete";
import CeroTable from "../../../../../components/CeroTable";
import { STATUS } from "../../../../../redux/constants";
import CeroInput from "../../../../../components/CeroInput";
import {
  getMaterialityIndustries,
  getSurvey,
  surveyQuestions,
  surveyResponse,
} from "../../../../../redux/actions";
import DashboardLayout from "../../../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ViewSurvey = () => {
  const classes = useStyles();
  const { id, answerId } = useParams();
  
  const [selectedValues, setSelectedValues] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [tableData, setTableData] = useState({});
  const [topicAnswers, setTopicAnswers] = useState({});
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const materialityQuestionsList = useSelector((state) => {
    const data = state.materiality.surveyQuestions.data;
    return Array.isArray(data) ? data : [];
  });

  const surveyData = useSelector(
    (state) => state.materiality.surveyResponse.data
  );
  const companyData = useSelector(
    (state) => state.materiality.getSurvey.data
  );
  const surveyStatus = useSelector(
    (state) => state.materiality.surveyQuestions
  );
  const companyName = useSelector(
    (state) => state.materiality.getSurveyQuestions.data.company_name
  );
  const industryData =
    useSelector((state) => state.materiality.getMaterialityIndustries.data) ||
    [];
  const tableColums = [
    {
      columnKey: "topic",
      columnId: "topic",
      columnHeader: "Topic",
      width: "35vw",
    },
    {
      columnKey: "relevance",
      columnId: "relevance",
      columnHeader: `The relevance of the topic for you as a ${companyName} stakeholder`,
      width: "35vw",
    },
    {
      columnKey: "impact",
      columnId: "impact",
      columnHeader: `Impact/Influence of ${companyName} on this topic`,
      width: "35vw",
    },
  ];

  useEffect(() => {
    dispatch(getMaterialityIndustries());
    dispatch(getSurvey())
    if (answerId) {
      dispatch(surveyResponse(answerId));
    }
    if (id) {
      dispatch(surveyQuestions(id));
    }
  }, [dispatch, answerId, id]);

  const industryList = Array.isArray(industryData)
    ? industryData.map((item) => ({
        id: item.id,
        label: item.industry_type,
      }))
    : [];
      
    useEffect(() => {
      if (surveyData && surveyData.answer) {
        const answers = surveyData.answer;
        const selected = {};
        const text = {};
        const table = {};
        const topic = {};
    
        answers.forEach((item) => {
          const { question_id, answer, question_type, answer_value } = item;
    
                  switch (question_type) {
              case "choose_from_option":
                selected[question_id] = answer;
                if (answer === "Other (please specify):") {
                  text[`other-${question_id}`] = answer_value || "";
                }
                break;
              case "text":
                text[question_id] = answer;
                break;
              case "table":
                table[question_id] = answer;
                break;
              case "choose_from_topic":
                topic[question_id] = answer;
                if (answer.includes("Other (please specify):")) {
                  text[`other-${question_id}`] = answer_value || ""; 
                }
                break;
              default:
                break;
            }
          });

        setSelectedValues(selected);
        setTextAnswers(text); 
        setTableData(table);
        setTopicAnswers(topic);
      }
    }, [surveyData]);
    

  const getTabledata = (question) =>
    question?.options?.map((item) => {
      const relevanceValue = tableData[question.id]?.[item]?.relevance || "";
      const impactValue = tableData[question.id]?.[item]?.impact || "";
      return {
        ...item,
        topic: item,
        relevance: (
          <div className={classes.editBox}>
            {relevanceValue}
          </div>
        ),
        impact: (
          <div className={classes.editBox}>
            {impactValue}
          </div>
        ),
      };
    });

    let surveyIndustry = Array.isArray(industryData) && industryData.filter((item) => item.id === companyData?.industry_type) ;

  return (
    <DashboardLayout>
      <Container className={classes.mainContainer}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        {surveyStatus?.status === STATUS.SUCCESS ? (
          <>
              <Typography
                variant="h4"
                className={classes.companyName}
              >
                {companyData?.company_name}
              </Typography>
            <Grid item xs={3} mr={3} className={classes.yearFilter}>
              <CeroAutoComplete
                classes={{
                  container: classes.autoComplete,
                  root: classes.textField,
                }}
                id="industry"
                label="Type of Industry"
                fullWidth
                value={surveyIndustry[0]?.industry_type}
                options={industryList || []}
                disabled={surveyData?.industry_type !== "None"}
              />
            </Grid>
            <Paper className={classes.container}>
              <div className={classes.paperTop}>
              <Typography variant="h6" className={classes.title}>
                ESG MATERIAL TOPICS SURVEY
              </Typography>
              <Typography variant="body" className={classes.completed}>
                <span style={{ color: "green"}}>Completed by</span>: {surveyData?.name}
              </Typography>
              </div>
              <div className={classes.questionList}>
                {materialityQuestionsList.map((question, index) => (
                  <Grid key={question.id} className={(question.question_type === 'text' || question.question_type === 'Text Field')
                    ? classes.inputGrid : ((selectedValues[question.id] === "Other (please specify):" || topicAnswers[question.id]?.includes("Other (please specify):")) 
                    ? classes.otherinput : classes.gridItem)} >
                    <Typography
                      fontSize={18}
                      fontWeight={500}
                      variant="h3"
                      paddingLeft={4}
                      paddingBottom={2}
                    >
                      {index + 1}. {question.question}
                    </Typography>
                    <Box display="flex" flexDirection="column" paddingLeft={6}>
                      {(question.question_type === "choose_from_option" ||
                        question.question_type === "Choose from Options") &&
                        question.options.map((option, idx) => (
                          <>
                          <FormControlLabel
                            key={idx}
                            control={
                              <Checkbox
                                className={classes.selectCheck}
                                checked={selectedValues[question.id] === option}
                                value={option}
                                sx={{
                                  color: "#5A404E !important", 
                                  '&.Mui-checked': {
                                    color: "#8C2148 !important", 
                                  },
                                }}                               
                                disabled
                              />
                            }
                            label={option}
                            className={classes.label}
                          />
                          {selectedValues[question.id] === "Other (please specify):" && option === "Other (please specify):" && (
                             <CeroInput
                             classes={{ input: classes.textAreaContainer}}
                             rows={3}
                             placeholder={"Please specify"}
                             id={`other-${question.id}`}
                             name={`other-${question.id}`}
                             label="Please specify"
                             multiline
                             value={textAnswers[`other-${question.id}`] || ""}
                             disabled
                           />
                           )}
                          </>
                        ))}
                      {question.question_type === "choose_from_topic" &&
                        question.options.map((option, idx) => (
                          <>
                          <FormControlLabel
                            key={idx}
                            control={
                              <Checkbox
                                className={classes.selectCheckbox}
                                checked={
                                  topicAnswers[question.id]?.includes(option) ||
                                  false
                                }
                                value={option}
                                sx={{
                                  color: "#5A404E !important", 
                                  '&.Mui-checked': {
                                    color: "#8C2148 !important", 
                                  },
                                }}  
                              />
                            }
                            label={option}
                          />
                          {topicAnswers[question.id]?.includes("Other (please specify):") && option === "Other (please specify):" && (
                            <CeroInput
                            classes={{ input: classes.textAreaContainer}}
                            rows={3}
                            placeholder={"Please type your Answer"}
                            id={`other-${question.id}`}
                            name={`other-${question.id}`}
                            label="Please specify"
                            multiline
                            value={textAnswers[`other-${question.id}`] || ""}
                            disabled
                          />)}</>
                        ))}
                      {(question.question_type === "text" ||
                        question.question_type === "Text Field") && (
                        <Box minHeight={80}>
                          <CeroInput
                            classes={{ input: classes.textAreaContainer}}
                            rows={3}
                            placeholder={"Please type your Answer"}
                            id={`answer-${question.id}`}
                            name={`answer-${question.id}`}
                            label="Answer"
                            multiline
                            value={textAnswers[question.id] || ""}
                            disabled
                          />
                        </Box>
                      )}
                      {question.question_type === "table" && (
                        <Grid>
                          <Typography
                            fontSize={18}
                            fontWeight={500}
                            variant="h3"
                            paddingLeft={4}
                            paddingY={3}
                          >
                            Please rate the importance of the following topics
                            for you in two main areas:
                          </Typography>
                          <Typography paddingLeft={6} paddingBottom={2} mt={3}>
                            a) The relevance of the topic for you as a{" "}
                            {companyName}
                            stakeholder
                          </Typography>
                          <Typography paddingLeft={6} paddingBottom={2} mb={3}>
                            b) The influence of {companyName} on this topic
                          </Typography>
                          <Typography paddingLeft={4} paddingY={3}>
                            This will allow us to identify material topics
                            relevant to our activities and disclose information
                            about our activities within{"\n"} these
                            topics.Please use a scale from 0 to 10
                          </Typography>
                          <Typography paddingLeft={4} paddingBottom={2}>
                            (0 - the topic is not important at all; 10 - the
                            topic is extremely important).
                          </Typography>
                          <CeroTable
                            columns={tableColums}
                            data={getTabledata(question)}
                            hasMore={false}
                            loading={false}
                            classes={{
                              headerCell: classes.headerCell,
                              tableBodyCell: classes.tableBodyCell,
                              tableHead: classes.tableTitle,
                              tableContainer: classes.tableContainer,
                            }}
                          />
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                ))}
              </div>
            </Paper>
          </>
        ) : surveyStatus?.status === STATUS.RUNNING ? (
          <div className={classes.loader}>
            <CircularProgress className={classes.icon} />
          </div>
        ) : (
          <Typography variant="h6" className={classes.tableTitle}>
            {surveyStatus?.message}
          </Typography>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ViewSurvey;

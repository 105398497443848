import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { auditorReaction, clearEmissionList, getEmissionList, resetAuditedData } from "../../../../redux/actions";
import CeroButton from "../../../../components/CeroButton";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { STATUS } from "../../../../redux/constants";
import EmployeeHireTable from "../../../EmissionList/EmployeeHireTable";
import { sampleYearArray } from "../../../../constants";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import CeroCheckboxTable from "../../../../components/CeroCheckboxTable";
import { getCookie } from "../../../../services/cookie";
import {
  MobileColumns,
  PurchasedElectricityColumns,
  RefrigerantsColumns,
  StationaryColumns,
  TransportationColumns,
  WasteCombustionColumns,
  WaterConsumptionColumns,
  WaterDischargeColumns,
} from "./AuditdataColumns";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StatusCard from "../AuditDetailsStatus";
import PoliticalContributionsApproval from "../../../ApprovalDetailsCard/PoliticalContributionsApproval";
import EmployeeHealthApproval from "../../../ApprovalDetailsCard/EmployeeHealthApproval";
import PerformanceAndCareerApproval from "../../../ApprovalDetailsCard/PerformanceAndCareerApproval";
import TrainingHoursApproval from "../../../ApprovalDetailsCard/TrainingHoursApproval";
import DiscriminationIncidentApproval from "../../../ApprovalDetailsCard/DiscriminationIncidentApproval";
import WorkerSafetyApproval from "../../../ApprovalDetailsCard/WorkerSafetyApproval";
import EmployeeBenefitsApproval from "../../../ApprovalDetailsCard/EmployeeBenefitsApproval";
import CeroPopUp from "../../../../components/CeroPopUp";
import EmployeeDiversityApproval from "../../../ApprovalDetailsCard/EmployeeDiversityApproval";
import SupplierScreeningApproval from "../../../ApprovalDetailsCard/SupplierScreeningApproval";
import SupplierHumanRightsApproval from "../../../ApprovalDetailsCard/SupplierHumanRightsApproval";
import LocalCommunitiesApproval from "../../../ApprovalDetailsCard/LocalCommunitiesApproval";
import SocialHumanRightsApproval from "../../../ApprovalDetailsCard/SocialHumanRightsApproval";
import AntiCorruptionDisclosureApproval from "../../../ApprovalDetailsCard/AntiCorruptionDisclosure";
import ManagementDiversityApproval from "../../../ApprovalDetailsCard/MangementDiversity";
import BoardDiversityApproval from "../../../ApprovalDetailsCard/BoardDiversity";
import AntiCompatitiveDisclosureApproval from "../../../ApprovalDetailsCard/AntiCompatitiveDisclosure";
import EconomicImpactApproval from "../../../ApprovalDetailsCard/EconomicImpact";
import SubsidiesAndFinancialAssistanceApproval from "../../../ApprovalDetailsCard/SubsidiesAndFinancialAssistance";
import AntiCorruptionTrainingApproval from "../../../ApprovalDetailsCard/AntiCorruptionTraining";
import EmployeeHireApproval from "../../../ApprovalDetailsCard/EmployeeHireAndTurnOver/index.jsx";

import { useSnackbar } from "notistack";

const DEFAULT_ITEMS_PER_PAGE = 20;

const CompanyEmissionList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id, type } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");

  const [canShowReviewButton, setCanShowReviewButton] = useState(true);
  const [page, setPage] = useState(1);
  const [auditConfirm, setAuditConfirm] = useState(false);
  const [reviewId, setReviewId] = useState([]);
  const [comment, setComment] = useState("");
  const [auditId, setAuditId] = useState([]);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isApprovalAll, setIsApprovalAll] = useState(false);
  const [sortDataAscending,setSortDataAscending] = useState(false);
  const [sortDataDescending,setSortDataDescending] = useState(false);


  const emissionData = useSelector(
    (state) => state.emission.emissionList.data.emissions
  );
  const totalEntries = useSelector(
    (state) => state.emission.emissionList.data.total_count
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionList.status
  );
  const auditedDataStatus = useSelector(
    (state) => state.audit.auditorReaction
  );
  const role = useSelector((state) => state.auth.role);

  const dataCount = emissionData?.length || emissionData?.based_on_gender?.length || emissionData?.gender_distribution?.length;
  
  const isAuditor = role.includes(rolesEnum.AUDITOR);
  const isOnlyAdmin =
    (role.includes(rolesEnum.ADMIN) && role.length === 1) || role === "admin";

  const emissionType = type;
  const getTableColumn = {
    stationary_combustion: StationaryColumns,
    mobile_combustion: MobileColumns,
    purchased_electricity: PurchasedElectricityColumns,
    water_discharge: WaterDischargeColumns,
    water_consumption: WaterConsumptionColumns,
    refrigerants: RefrigerantsColumns,
    transportation: TransportationColumns,
    waste: WasteCombustionColumns,
  };

  var x = sessionStorage.getItem("year");
  const [filter, setFilter] = useState({
    month: selectedMonth,
    year: selectedYear || Number(x) || sampleYearArray[0].key,
    facility_id: selectedFacility,
  });

  const distributionIds = {};
  for (const key in emissionData) {
    if (Object.prototype.hasOwnProperty.call(emissionData, key) && Array.isArray(emissionData[key])) {
      distributionIds[key] = emissionData[key].map(item => item.id);
    }
  }
  
 const filteredIds = Object.values(distributionIds).flat()
 const duplicateIds =  filteredIds.filter((item, index) => filteredIds.indexOf(item) === index);
 const topicIds = Array.isArray(emissionData) ? (emissionData && emissionData?.map((item) => item.id)) : duplicateIds;

  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [filter]);

  const onLoadMore = (limit, pageNumber) => {
    const skip = (pageNumber - 1) * 20;
    const newLimit = DEFAULT_ITEMS_PER_PAGE * pageNumber;
    const filterRequest = {
      limit: newLimit,
      skip,
      month: selectedMonth,
      year: selectedYear || Number(x) || sampleYearArray[0].key,
      facility_id: selectedFacility,
      period: period,
      country: country,
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    dispatch(
      getEmissionList(
        emissionType,
        filterRequest,
        isAuditor,
        company,
        typeof selectedFacility === "string"
          ? selectedFacility === "null"
            ? null
            : selectedFacility.split(",")
          : selectedFacility,
        period,
        country
      )
    );
  };
  
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, newPage);
  };

  const startItem = (page - 1) * DEFAULT_ITEMS_PER_PAGE + 1;
  const endItem = Math.min(
    startItem + DEFAULT_ITEMS_PER_PAGE - 1,
    totalEntries
  );

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const tableTopics = [
    "stationary_combustion",
    "mobile_combustion",
    "refrigerants",
    "transportation",
    "purchased_electricity",
    "water_discharge",
    "water_consumption","waste"
  ].includes(emissionType);

  const multipleRecordTopics = [
    "employees_benefit",
    "employee_health_safety_incident_record",
    "worker_safety_training_procedures",
    "training_hours_per_employee",
    "performance_and_career_development_programs"
  ].includes(emissionType);

  const commonTopics = [
    "employees_hire_and_turnover",
    "employees_diversity_and_ratio_of_basic_salary",
    "board_diversity",
  ]
  const [hideButton, setHideButton] = useState(null);
  const [singleRecord, setSingleRecord] = useState(null);
  useEffect(() => {
    const hideBtn = (emissionType === "management_diversity" || emissionType === "board_diversity") 
  ? emissionData?.based_on_gender?.filter(item => item.status === "approved") 
  : (commonTopics.includes(emissionType) 
    ? emissionData?.gender_distribution?.filter(item => item.status === "approved") 
    : Array.isArray(emissionData) ? emissionData?.filter(item => item.status === "approved") : null);
    const singleRec = hideBtn?.[0]?.id || hideBtn?.based_on_gender?.[0]?.id || hideBtn?.gender_distribution?.[0]?.id;
    setHideButton(hideBtn);
    setSingleRecord(singleRec);
  },[emissionData])
    
    const getHeader = () => {
    return (
      <Box className={classes.tableTopic}>
        <Typography variant="h7" component="span">
          <span className={classes.tableTitle}>
            {emissionType.replace(/_/g, " ")}
          </span>
        </Typography>
        <Box>
          {!tableTopics && hideButton?.length > 0 && (
            <><CeroButton
            buttonText={hideButton?.length > 1 ? "Validate All" : "Validate"}
            variant="contained"
            className={classes.validateButton}
            onClick={handleOpenPopup}
          />
          {hideButton?.length == 1 && <CeroButton
            buttonText="Review"
            variant="contained"
            className={classes.buttonPrimary}
            onClick={()=>handleReviewPopup(singleRecord)}
          />}</>)}
          <CeroButton
            buttonText="Back"
            variant="outlined"
            className={classes.buttonSecondary}
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          />
        </Box>
        <CeroPopUp
          primaryPopUp={{
            open: isPopupOpen,
            onClose: () => setIsPopupOpen(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewPopup,
            onClose: () => setReviewPopup(false),
            content: getReviewComments(),
            header: { title: "Review Comments" },
          }}
        />
      </Box>
    );
  };

  const handleCheckedRowsChange = (newCheckedRows) => {
    setCheckedRows(newCheckedRows);
  };

  const getStatusColor = (item) => {
    if (item === "approved") {
      return (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      );
    } else if (item === "submitted") {
      return (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "audited") {
      return (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      );
    } else if (item === "review") {
      return (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalReview} />
        </Tooltip>
      );
    } else if (item === "verified") {
      return (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      );
    }else if (item === "added") {
      return (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      );
    } else if (item === "assigned") {
      return (
        <Tooltip title="assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "Awaiting approval") {
      return (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "In Progress") {
      return (
        <Tooltip title="In Progress" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "pending_approval") {
      return (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={item} arrow>
          <Chip className={classes.signalAdded} />
          {item}
        </Tooltip>
      );
    }
  };

  const handleFilterSelect = (value) => {
    setFilteredData(value);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setReviewPopup(false);
  };

  const getEmissionData = () => {
    if (!Array.isArray(emissionData)) return [];
    const data = emissionData.map((item) => ({
      ...item,
      status: item.status ? getStatusColor(item.status) : "",
      treatment_required: item.treatment_required ? "Yes" : "No",
    }));
    if (filteredData) {
      return data.filter((item) => item.status?.props?.title === filteredData);
    }
    if(sortDataAscending){ 
        return data.sort((a, b) => a.display_status.localeCompare(b.display_status)); 
    }
    else if(sortDataDescending){
        return data.sort((a, b) => b.display_status.localeCompare(a.display_status));  
    }
    return data;
  };

  const onSelectEmissionData = (emission) => {
    isAuditor
      ? navigate(
          `/emissions/${emissionType}/details/${emission.id}/?company=${company}`
        )
      : navigate(`/emissions/${emissionType}/details/${emission.id}`);
  };

  const data = tableTopics && emissionData && emissionData?.map((item) => item.status);
  
  const hasApprove = data && data?.includes('approved');

  const allVerified = tableTopics && !hasApprove
  
  const getIcon = () => {
    return (
      <>
        <Tooltip title="Validate" arrow>
          <CheckCircleOutlineOutlinedIcon
            onClick={handleOpenPopup}
            className={classes.icon}
          />
        </Tooltip>
        {canShowReviewButton && (
          <Tooltip title="Review" arrow>
            <ArrowCircleLeftOutlinedIcon
              onClick={handleReviewPopup}
              className={classes.icon}
            />
          </Tooltip>
        )}
        <CeroPopUp
          primaryPopUp={{
            open: isPopupOpen,
            onClose: () => setIsPopupOpen(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewPopup,
            onClose: () => setReviewPopup(false),
            content: getReviewComments(),
            header: { title: "Review Comments" },
          }}
        />
      </>
    );
  };

  const handleAudit = (item) => {
    setAuditConfirm(true);
    setAuditId(item);
  };

  const handleReviewPopup = (item) => {
    setReviewPopup(true);
    setReviewId(item);
    setAuditId(item);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const onSubmitReview = () => {
    
    setReviewPopup(false);  
    if(tableTopics){
      dispatch(auditorReaction(company, id, checkedRows, comment, "review"));
     }
     else if (multipleRecordTopics && emissionData?.length > 1){
      dispatch(auditorReaction(company, id, [reviewId], comment, "review"));
     }else if (multipleRecordTopics && emissionData?.length === 1){
      dispatch(auditorReaction(company, id, topicIds, comment, "review"));
     }else if (reviewId){
      dispatch(auditorReaction(company, id, [reviewId], comment, "review"));  
     }
     else{
      dispatch(auditorReaction(company, id, topicIds, comment, "review"));  
     }
      setComment("")
  };

  const handleIndividualApprove = () => {
    dispatch(auditorReaction(company, id, [auditId], comment, "verified"));
  };

  const handleAction = () => {
    setIsApprovalAll(true);
    if(tableTopics){
    dispatch(auditorReaction(company, id, checkedRows, comment, "verified"));
   }else{
    dispatch(auditorReaction(company, id, topicIds, comment, "verified"));
   }
    handleClosePopup();
  };

  useEffect(() => {
    if (auditedDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar(auditedDataStatus?.data?.message, { variant: 'success' });
      const filterRequest = {
        month: selectedMonth,
        year: selectedYear || Number(x) || sampleYearArray[0].key,
        facility_id: selectedFacility,
        period: period,
        country: country,
      };
      dispatch(
        getEmissionList(
          emissionType,
          filterRequest,
          isAuditor,
          company,
          typeof selectedFacility === "string"
            ? selectedFacility === "null"
              ? null
              : selectedFacility.split(",")
            : selectedFacility,
          period,
          country,
        )
      );
      dispatch(resetAuditedData())
    } else if (auditedDataStatus.status === STATUS.ERROR) {
      dispatch(resetAuditedData())
      enqueueSnackbar(auditedDataStatus.data.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
    }
  }, [enqueueSnackbar, auditedDataStatus, dispatch, id, type, dataCount, company])

  const getConfirmation = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to validate all records?
        </Typography>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleAction}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleClosePopup}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const getReviewComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.reviewButtons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSubmitReview}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewPopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  const getAuditConfirm = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to approve?
        </Typography>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setAuditConfirm(false);
              handleIndividualApprove();
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setAuditConfirm(false)}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };  

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {emissionDataStatus === STATUS.SUCCESS ? (
          <>
            {getHeader()}
            {emissionType === "employees_hire_and_turnover" ? (
              <>
                {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
                <EmployeeHireApproval
                  emissionData={emissionData}
                  dataStatus={emissionDataStatus}
                  onLoadMore={onLoadMore}
                  emissionType={emissionType}
                  isAuditor={isAuditor}
                  company={company}
                  isOnlyAdmin={!isOnlyAdmin}
                  period={period}
                  isDeleteActionRequired={true}
                  handleCallback={handleAudit}
                  handleReviewCallback={handleReviewPopup}
                />
                <CeroPopUp
                primaryPopUp={{
                  open: auditConfirm,
                  onClose: () => setAuditConfirm(false),
                  content: getAuditConfirm(),
                  header: { title: "" },
                }}
                showCloseButton={true}
                classes={{ dialog: classes.dialog }}
                />
              </>
            ) : emissionType === "employees_benefit" ? (
              <>
              <EmployeeBenefitsApproval
                emissionData={emissionData}
                handleCallback={handleAudit}
                handleReviewCallback={handleReviewPopup}
              /><CeroPopUp
              primaryPopUp={{
                open: auditConfirm,
                onClose: () => setAuditConfirm(false),
                content: getAuditConfirm(),
                header: { title: "" },
              }}
              showCloseButton={true}
              classes={{ dialog: classes.dialog }}
            /></>
              
            ) 
            : emissionType === "employee_health_safety_incident_record" ? (
              <>
              <EmployeeHealthApproval
                emissionData={emissionData}
                handleCallback={handleAudit}
                handleReviewCallback={handleReviewPopup}
              /><CeroPopUp
              primaryPopUp={{
                open: auditConfirm,
                onClose: () => setAuditConfirm(false),
                content: getAuditConfirm(),
                header: { title: "" },
              }}
              showCloseButton={true}
              classes={{ dialog: classes.dialog }}
            /></>
            ) : emissionType === "worker_safety_training_procedures" ? (
              <>
              <WorkerSafetyApproval
                emissionData={emissionData}
                handleCallback={handleAudit}
                handleReviewCallback={handleReviewPopup}
              /><CeroPopUp
              primaryPopUp={{
                open: auditConfirm,
                onClose: () => setAuditConfirm(false),
                content: getAuditConfirm(),
                header: { title: "" },
              }}
              showCloseButton={true}
              classes={{ dialog: classes.dialog }}
            /></>
            ) : emissionType === "discrimination_incident_record" ? (
              <>
              <DiscriminationIncidentApproval
                emissionData={emissionData}
                handleCallback={handleAudit}
                handleReviewCallback={handleReviewPopup}
              /><CeroPopUp
              primaryPopUp={{
                open: auditConfirm,
                onClose: () => setAuditConfirm(false),
                content: getAuditConfirm(),
                header: { title: "" },
              }}
              showCloseButton={true}
              classes={{ dialog: classes.dialog }}
            /></>
            ) : emissionType === "training_hours_per_employee" ? (
              <>
              <TrainingHoursApproval
                emissionData={emissionData}
                handleCallback={handleAudit}
                handleReviewCallback={handleReviewPopup}
              /><CeroPopUp
              primaryPopUp={{
                open: auditConfirm,
                onClose: () => setAuditConfirm(false),
                content: getAuditConfirm(),
                header: { title: "" },
              }}
              showCloseButton={true}
              classes={{ dialog: classes.dialog }}
            /></>
            ) : emissionType ===
              "performance_and_career_development_programs" ? (
                <>
              <PerformanceAndCareerApproval
                emissionData={emissionData}
                handleCallback={handleAudit}
                handleReviewCallback={handleReviewPopup}
              /><CeroPopUp
              primaryPopUp={{
                open: auditConfirm,
                onClose: () => setAuditConfirm(false),
                content: getAuditConfirm(),
                header: { title: "" },
              }}
              showCloseButton={true}
              classes={{ dialog: classes.dialog }}
            /></>
            ) :
             emissionType ===
            "employees_diversity_and_ratio_of_basic_salary" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <EmployeeDiversityApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            />
            <CeroPopUp
              primaryPopUp={{
                open: auditConfirm,
                onClose: () => setAuditConfirm(false),
                content: getAuditConfirm(),
                header: { title: "" },
              }}
              showCloseButton={true}
              classes={{ dialog: classes.dialog }}
            /></>
            ) : emissionType ===
            "supplier_screening_using_enviormental_social_criteria" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <SupplierScreeningApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "suppliers_human_rights" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <SupplierHumanRightsApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "local_communities" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <LocalCommunitiesApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "social_engagement_human_rights_training" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <SocialHumanRightsApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "political_contributions" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <PoliticalContributionsApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "social_engagement_human_rights_training" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <SocialHumanRightsApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "board_diversity" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <BoardDiversityApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "management_diversity" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <ManagementDiversityApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "anti_corruption_disclosure" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <AntiCorruptionDisclosureApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "anti_competitive_disclosure" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <AntiCompatitiveDisclosureApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "economic_impact" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <EconomicImpactApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "subsidies_financial_assistance" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <SubsidiesAndFinancialAssistanceApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : emissionType ===
            "anti_corruption_training" ? (
              <>
              {/* <StatusCard emissionData={emissionData} emissionType={emissionType}/> */}
              <AntiCorruptionTrainingApproval
              emissionData={emissionData}
              handleCallback={handleAudit}
              handleReviewCallback={handleReviewPopup}
            /><CeroPopUp
            primaryPopUp={{
              open: auditConfirm,
              onClose: () => setAuditConfirm(false),
              content: getAuditConfirm(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          /></>
            ) : (
              <>
                <CeroCheckboxTable
                  columns={getTableColumn[type] || []}
                  data={getEmissionData()}
                  hasMore={false}
                  loading={false}
                  loadMore={onLoadMore}
                  classes={{ tableContainer: classes.tableContainer }}
                  showSelectAllCheckbox={!allVerified}
                  isSelectable
                  showDropdown={true}
                  checkedRows={checkedRows}
                  onCheckedRowsChange={handleCheckedRowsChange}
                  showIcon={
                    !allVerified && getIcon() 
                  }
                  showFilterIcon={true}
                  onSelectRow={onSelectEmissionData}
                  onFilterSelect={handleFilterSelect}
                  setSortDataAscending={setSortDataAscending}
                  setSortDataDescending={setSortDataDescending}
                  sortDataAscending={sortDataAscending}
                  sortDataDescending={sortDataDescending}
                  showSort={true}
                />
                <CeroPopUp
                  primaryPopUp={{
                    open: auditConfirm,
                    onClose: () => setAuditConfirm(false),
                    content: getAuditConfirm(),
                    header: { title: "" },
                  }}
                  classes={{ dialog: classes.dialog }}
                />
                {totalEntries > 0 && (
                  <div className={classes.pagination}>
                    <Typography variant="body" style={{ fontSize: 12 }}>
                      Showing {startItem} to {endItem} of {totalEntries} entries
                    </Typography>
                    <Pagination
                      count={Math.ceil(totalEntries / DEFAULT_ITEMS_PER_PAGE)}
                      page={page}
                      color="primary"
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default CompanyEmissionList;
